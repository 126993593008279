import './App.css';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import ContactUs from './pages/ContactUs/ContactUs';

// import MechanicalEngineering from './components/pages/Services/engineering/MechanicalEngineering/MechanicalEngineering';
// import Electrical_Engineering from './components/pages/Services/engineering/electrical-engineering/Electrical_Engineering';
// import ElectronicEngineering from './components/pages/Services/engineering/Electronic Engineering/Electronic_Engineering';
import Registration from './pages/Registration/Registration';
import StudentInfo from './pages/Registration/StudentInfo/StudentInfo';
import ScrollTop from './utils/ScrollTop';
import Scroll from './components/ScrollToTop/topScroll';
// import moduleTest from './components/pages/course/module/modulestest';
import { CircleToBlockLoading } from 'react-loadingg';
import Research from './pages/Research/Research';
// import FreeDemo from './pages/FreeDemo/FreeDemo'
// import Electronics from "./pages/course/electronics/Electronics";
import deadPage from './pages/deadPage';

const Header = lazy(() => import('./components/Header/Header'));
const Home = lazy(() => import('./pages/Home/Home'));
const About = lazy(() => import('./pages/About/About'));
const Founders = lazy(() =>
  import('./pages/About/Founders/Co-founders')
);
const Coaches = lazy(() => import('./pages/About/Coaches/Coaches'));
// const Projects = lazy(() => import('./pages/about/Projects/Projects'));
const Blog = lazy(() => import('./pages/About/Blog/Blog'));
const blog1 = lazy(() => import('./pages/About/Blogs/blogs'));
// const Course = lazy(() => import('./pages/course/courses/Course'));
const Module = lazy(() => import('./pages/Course/module/module'));
// const Services = lazy(() => import('./pages/Services/Services')); 
const Ict = lazy(() => import('./pages/Services/ict/ICT'));
const Engineering = lazy(() =>
  import('./pages/Services/engineering/engineering')
);
const Steam = lazy(() => import('./pages/Services/steam/steam'));
// const MechanicalEngineering = lazy(() => import('./pages/Services/engineering/MechanicalEngineering/MechanicalEngineering'));
// const Electrical_Engineering = lazy(() => import('./pages/Services/engineering/electrical-engineering/Electrical_Engineering'));
// const ElectronicEngineering = lazy(() => import('./pages/Services/engineering/Electronic Engineering/Electronic_Engineering'));
// const Shop = lazy(() => import('./pages/shop/Shop'));
// const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'));
const Footer = lazy(() => import('./components/Footer/Footer'));
const FreeDemo = lazy(() => import('./pages/FreeDemo/FreeDemo'));

// import ClipLoader from "react-spinners/ClipLoader";
function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="App">
      {loading ? (
        <div className="loader">
          <CircleToBlockLoading
            size="small"
            gitspeed={4}
            color="#0c50a3"
            loading={loading} //3 secs
          />
        </div>
      ) : (
        <BrowserRouter>
          <Suspense
            fallback={
              <div className="loader">
                <CircleToBlockLoading
                  size="small"
                  speed={4}
                  color="#0c50a3"
                  loading={loading} //3 secs
                />
              </div>
            }
          >
            <switch>
              <ScrollTop />
              <Header />
              {/* <ScrollTop />
            <Header2 /> */}
              <ScrollTop />
              <Route exact path="/" component={Home} />
              <ScrollTop />
              <Route exact path="/profile" component={About} />
              {/* <ScrollTop /> */}
              {/* <Route exact path="/services" component={Services} /> */}
              {/* <Route exact path="/services" component={deadPage} /> */}
              <ScrollTop />

              <Route exact path="/blog" component={Blog} />
              {/* <Route exact path="/blog" component={deadPage}/> */}
              <ScrollTop />
              <Route exact path="/blogs" component={blog1} />

              <ScrollTop />

              <Route exact path="/module" component={Module} />
              <ScrollTop />
              {/* <Route exact path="/moduleall" component={moduleTest} />
              <ScrollTop /> */}

              <Route exact path="/Coaches" component={Coaches} />
              {/* <Route exact path="/Coaches" component={deadPage} /> */}
              <ScrollTop />
              <Route exact path="/founders" component={Founders} />
              {/* <Route exact path="/Coaches" component={deadPage} /> */}
              <ScrollTop />
              <Route exact path="/Register" component={Registration} />
              <ScrollTop />
              {/* <Route exact path="/Shop" component={Shop} /> */}
              {/* <Route exact path="/Shop" component={deadPage} /> */}
              <ScrollTop />

              <Route exact path="/Contact-us" component={ContactUs} />
              <ScrollTop />

              <ScrollTop />
              <Route
              //exact
              //path="/TinkerCAD-and-3D-Printing"
              //component={TinkerCad3DPrinting}
              />
              <ScrollTop />

              <ScrollTop />
              <Route exact path="/ict" component={Ict} />
              {/* <Route
              exact
              path="/ict"
              component={deadPage}
            /> */}
              <ScrollTop />
              <Route exact path="/Student-info" component={StudentInfo} />
              <ScrollTop />
              <Route exact path="/engineering" component={Engineering} />
              <ScrollTop />
              <Route exact path="/steam" component={Steam} />

              <ScrollTop />
              <Route exact path="/deadPage" component={deadPage} />
              <ScrollTop />

              <ScrollTop />
              <Route exact path="/search" component={Research} />
              <ScrollTop />

              {/* <ScrollTop />

            <Route path="*">
              <deadPage />
            </Route> */}
              {/* <ScrollTop /> */}
              <ScrollTop />
              <Route exact path="/free-demo" component={FreeDemo} />

              <Footer />
            </switch>
          </Suspense>
        </BrowserRouter>
      )}
      <Scroll />
    </div>
  );
}

export default App;
